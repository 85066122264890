<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Stands Solicitados</span>
        </v-card-title>
        <v-card-text>
          <div class="row" style="min-height: 500px;">
            <div class="col-12">
              <v-simple-table dense v-if="selectedStands.length>0">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Espacio
                    </th>
                    <th class="text-left">
                      Código
                    </th>
                    <th class="text-left">
                      Categoría
                    </th>
                    <th class="text-left">
                      Metros cuadrados
                    </th>
                    <th class="text-left">
                      Total valor en COP ({{ taxNomenclature }} incluido)
                    </th>
                    <th v-if="appCountry === 'Perú'" class="text-left">
                      Total valor en S/ ({{ taxNomenclature }} incluido)
                    </th>
                    <th class="text-left">
                      Total valor en USD ({{ taxNomenclature }} incluido)
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="selectedStand in selectedStands"
                    :key="selectedStand.id"
                  >
                    <td>{{ selectedStand.placeName }}</td>
                    <td>{{ selectedStand.code.toUpperCase() }}</td>
                    <td>{{ selectedStand.categoryName }}</td>
                    <td>{{ selectedStand.area }}</td>
                    <td class="text-right">
                      ${{ selectedStand.copValue + selectedStand.copTax | currency }}
                    </td>
                    <td v-if="appCountry === 'Perú'" class="text-right">
                      S/ {{ selectedStand.penValue + selectedStand.penTax | currency }}
                    </td>
                    <td class="text-right">
                      {{ selectedStand.usdValue + selectedStand.usdTax | currency }} USD
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import { BOOKINGS_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'

export default {
  name: 'StandsInfoModal',
  data () {
    return {
      appCountry: process.env.VUE_APP_COUNTRY,
      taxNomenclature: process.env.VUE_APP_TAX_NOMENCLATURE,
      selectedStands: [],
      isActive: false
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin],
  methods: {
    async open (bookingId) {
      this.isActive = true
      try {
        this.showLoader()
        await this.getStands(bookingId)
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async getStands (bookingId) {
      const response = await this.get(BOOKINGS_URL + '/' + bookingId + '/stands')
      this.selectedStands = response.data
    }
  }
}
</script>

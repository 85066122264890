const headers = [
  {
    text: process.env.VUE_APP_RUT_LABEL,
    value: 'identification',
    sortable: false
  },
  {
    text: 'Nombre comercial',
    value: 'companyName',
    sortable: false
  },
  {
    text: 'País compañía',
    value: 'countryName',
    sortable: false
  },
  {
    text: 'País facturación',
    value: 'invoiceCountry',
    sortable: false
  },
  {
    text: 'Contactos',
    value: 'firstContactName',
    sortable: false
  },
  {
    text: 'Stands / N° Stands',
    value: 'standsAmount',
    sortable: false,
    width: '150px'
  },
  {
    text: 'M2',
    value: 'standsTotalArea',
    sortable: false
  },
  {
    text: 'Valor a pagar',
    value: 'totalValue',
    sortable: false,
    width: '150px'
  },
  {
    text: 'Última modificación',
    value: 'updated_at',
    sortable: false,
    show: true
  },
  {
    text: 'Estado',
    value: 'currentStatus',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '380px'
  }
]
export default headers

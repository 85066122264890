<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Información de los contactos</span>
        </v-card-title>
        <v-card-text>
          <div class="row" v-if="contacts.length">
            <div class="col-12">
              <h3>Contacto 1</h3>
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Nombre:</span> <span class="text-uppercase">{{
                contacts[0].name
              }}</span>
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Cargo:</span> {{ contacts[0].positionDetail }}
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">E-mail:</span> {{ contacts[0].email }}
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Teléfono:</span> {{ contacts[0].phone }}
              <span v-if="contacts[0].extension">ext {{ contacts[0].extension }}</span>
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Móvil:</span> {{ contacts[0].mobilePhone }}
            </div>
          </div>
          <div class="row" v-if="contacts.length">
            <div class="col-12">
              <h3>Contacto 2</h3>
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Nombre:</span> <span class="text-uppercase">{{
                contacts[1].name
              }}</span>
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Cargo:</span> {{ contacts[1].positionDetail }}
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">E-mail:</span> {{ contacts[1].email }}
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Teléfono:</span> {{ contacts[1].phone }}
              <span v-if="contacts[0].extension">ext {{ contacts[1].extension }}</span>
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Móvil:</span> {{ contacts[1].mobilePhone }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import { BOOKINGS_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  data () {
    return {
      bookingId: null,
      contacts: [],
      isActive: false
    }
  },
  methods: {
    async getContacts () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/contacts')
      this.contacts = response.data
    },
    async open (bookingId) {
      this.bookingId = bookingId
      this.isActive = true
      try {
        this.showLoader()
        await this.getContacts()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    }
  },
  mixins: [
    crudServiceMixin,
    notificationMixin,
    loaderMixin
  ],
  name: 'ContactInfoModal'
}
</script>

export const BOOKING_STATUS = {
  new: {
    code: 'new',
    description: 'Nueva',
    color: '#222c33'
  },
  successful: {
    code: 'successful',
    description: 'Completa',
    color: '#13e23f'
  },
  incomplete: {
    code: 'incomplete',
    description: 'Incompleta',
    color: '#FF8000'
  },
  rejected: {
    code: 'rejected',
    description: 'Rechazada',
    color: '#F44336'
  }
}
export default BOOKING_STATUS

<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      width="400"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Auditoria</span>
        </v-card-title>
        <v-card-text>
          <div class="row" style="min-height: 500px;">
            <v-simple-table dense v-if="statuses.length>0">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Estado
                  </th>
                  <th class="text-left">
                    Fecha
                  </th>
                  <th class="text-left">
                    Usuario
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="status in statuses"
                  :key="status.id"
                >
                  <td>{{ bookingStatus[status.status].description }}</td>
                  <td>{{ formatDate(status.created_at) }}</td>
                  <td>{{ status.bookingEditorName }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import { BOOKINGS_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import BOOKING_STATUS from '@/constants/BookingStatusContants'
import moment from 'moment'

export default {
  name: 'AuditModal',
  data () {
    return {
      bookingStatus: BOOKING_STATUS,
      statuses: [],
      isActive: false
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin],
  methods: {
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY hh:mm:ss')
    },
    async open (bookingId) {
      this.isActive = true
      try {
        this.showLoader()
        await this.getAudit(bookingId)
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async getAudit (bookingId) {
      const response = await this.get(BOOKINGS_URL + '/' + bookingId + '/audit')
      this.statuses = response.data
    }
  }
}
</script>
